import * as React from "react";
import { Layout } from "../components/Layout";
import ServicesFirst from "../components/services/servicesfirst";
import HeaderCompo from "../components/header/Header";
import ServiceBanner from "../components/services/serviceBanner";
import ServiceCover from "../components/services/serviceCover";
import serviceBg from "../images/serviceBg.png";
import SEO from "../components/seo";


export default function servieces() {
  return (
    <>
      <Layout>
      <SEO title="Services | Zector" description="A list of all of Zector's service offerings."/>
        
        <HeaderCompo
          headerTitle="Our Services"
          headerBtn="Tell us about your project"
          headerbg={serviceBg}
        />
        <ServicesFirst />
        <ServiceBanner />
        <ServiceCover />
      </Layout>
    </>
  );
}
