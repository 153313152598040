import React from "react";
import * as  styles from "./services.module.css";
import cardBg from "../../images/card_bg.png";
import icon1 from "../../images/service_icons/icon1.png";
import icon2 from "../../images/service_icons/icon2.png";
import icon3 from "../../images/service_icons/icon3.png";
import icon4 from "../../images/service_icons/icon4.png";
import icon5 from "../../images/service_icons/icon5.png";
import icon6 from "../../images/service_icons/icon6.png";
import { ArrowRightOutlined } from "@ant-design/icons";

export default function ServicesFirst() {
  return (
    <div className={`${styles.services_main}`}>
      <div className="main-container container-lg container-md container-sm d-flex justify-center flex-column">
        <div
          className={`${styles.service_heading} text-center pl-30 pr-30 bs-bb`}
        >
          <h2 className={`${styles.service_title} mb-30`}>
            {" "}
            Our <span className="text-zinc">Services</span>
          </h2>
          <p className={`${styles.title_desc} m-auto`}>
            
          </p>
        </div>
        <div
          className={`${styles.service_card_box} flex-100 flex-wrap jsutify-between-min-sm d-flex justify-center item-center`}
        >
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center" style={{fontFamily: `Montserrat, sans-serif`}}>
              <i className={`${styles.card_icon}`}>
                <img src={icon1} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{marginBottom: `20px`}} style={{fontFamily: `Montserrat, sans-serif`}}>
                Media Services
              </h3>
            </div>
            <div className={`${styles.card_text} `}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              Our engineers develop live stream and video on demand solutions to give you more control over your content and to increase customer engagement. We also provide payment integration as a core feature for subscription media services.
              </p>
            </div>
          </div>
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center">
              <i className={`${styles.card_icon}`}>
                <img src={icon2} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{marginBottom: `20px`}} style={{fontFamily: `Montserrat, sans-serif`}}>
                Web Development
              </h3>
            </div>
            <div className={`${styles.card_text} `}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              From user interface development to API development, our engineers can write modular, well-documented, responsive code with a diverse stack of technologies to fit each customer's needs. We make sure to get it right the first time.
              </p>
            </div>
          </div>
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center">
              <i className={`${styles.card_icon}`}>
                <img src={icon3} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{marginBottom: `30px`}} style={{fontFamily: `Montserrat, sans-serif`}}>
                Mobile App Development
              </h3>
            </div>
            <div className={`${styles.card_text} `} style={{marginTop: `20px`}}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              Our mobile development team can help you put your company in your customer's pocket by increasing brand engagement with an intuitive, creative and interactive cross-platform application. Cross platform development allows us to write your codebase once and run everywhere.
              </p>
            </div>
          </div>
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center">
              <i className={`${styles.card_icon}`}>
                <img src={icon4} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{marginBottom: `20px`}} style={{fontFamily: `Montserrat, sans-serif`}}>
                Web Scraping
              </h3>
            </div>
            <div className={`${styles.card_text} `}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              The internet is full of data waiting to be extracted, transformed, and analyzed by businesses. Our engineers create custom web scraping solutions to quickly gather the data that you need and provide it in a elegant structure.
              </p>
            </div>
          </div>
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center">
              <i className={`${styles.card_icon}`}>
                <img src={icon5} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{fontFamily: `Montserrat, sans-serif`}}>
                UI/UX Design
              </h3>
            </div>
            <div className={`${styles.card_text} `}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              Great user experiences are important to us for every software that we create. We take the time to understand your users pain points to ensure your users get the best experience.
              </p>
            </div>
          </div>
          <div
            className={`${styles.service_card} bs-bb justify-between home-card pl-30 pr-30 d-flex flex-column item-center`}
          >
            <div className="d-flex flex-column item-center">
              <i className={`${styles.card_icon}`}>
                <img src={icon6} alt="headphon" />
              </i>
              <h3 className={`${styles.service_card_heading} `} style={{fontFamily: `Montserrat, sans-serif`}}>
                QA & Support
              </h3>
            </div>
            <div className={`${styles.card_text} `}>
              <p className={`${styles.service_card_desc} m-auto`} style={{fontFamily: `Montserrat, sans-serif`}}>
              From functionality to security, our team has you covered. We pride ourselves on our communication and provide weekly updates with a fair turn around time in customer communication.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
