import React from "react";
import * as  styles from "./services.module.css";
import art from "../../images/cover_art.png";

export default function ServiceCover() {
  return (
    <div
      className={`${styles.service_cover} d-flex item-center index-1 ps-relative`}
    >
      <div className={`${styles.bg_art} ps-absolute `}>
        <img alt="ilustration" src={art} />
      </div>

      <div className="container-el container-lg  ">
        <div className={`${styles.cover_text} pl-30 pr-30 bs-bb `}>
          <h3 className={`${styles.cover_title} mb-30 `} style={{fontFamily: `Montserrat, sans-serif`}}>
            We Are Hear To <span className="text-zinc">Help You With</span>
          </h3>
          <p className={`${styles.cover_desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
            Designing web and mobile solutions for the cloud to attract investors.
          </p>
        </div>
      </div>
    </div>
  );
}
