import React from "react";
import * as styles from "./services.module.css";
import { Link } from "gatsby";

export default function ServiceBanner() {
  return (
    <div
      className={`${styles.servic_text_main}  d-flex justify-center item-center`}
    >
      <div
        className={`${styles.banner_text} m-auto pl-30 pr-30 pr-15-sm pl-15-sm`}
      >
        <h3 className="cover_text mb-60 text-center" style={{fontFamily: `Montserrat, sans-serif`}}>
          Let's build something <span className="text-zinc"> together</span>
        </h3>
        <Link to="/contact" className="btn bs-bb no-underline text-white m-auto" style={{fontFamily: `Montserrat, sans-serif`}}>
          Tell us about your project
        </Link>
      </div>
    </div>
  );
}
