// extracted by mini-css-extract-plugin
export const services_main = "services-module--services_main--3YqtD";
export const service_heading = "services-module--service_heading--2Y8Ya";
export const service_title = "services-module--service_title--18iBM";
export const title_desc = "services-module--title_desc--1ZcAu";
export const service_card = "services-module--service_card--3N8uB";
export const card_icon = "services-module--card_icon--hlh86";
export const service_card_heading = "services-module--service_card_heading--38ipy";
export const service_card_desc = "services-module--service_card_desc--DIRIq";
export const card_btn = "services-module--card_btn--3kfOj";
export const servic_text_main = "services-module--servic_text_main--382ti";
export const service_cover = "services-module--service_cover--7MlDn";
export const cover_text = "services-module--cover_text--22j-l";
export const cover_title = "services-module--cover_title--2NUdl";
export const cover_desc = "services-module--cover_desc--2nnnn";
export const bg_art = "services-module--bg_art--dC79Q";
export const cover_btn = "services-module--cover_btn--ofnsF";